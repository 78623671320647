import React from "react";
import axios from "axios";
import logo from "./logo.png";
import "./App.css";

class PlayerForm extends React.Component {
  state = {
    playerId: "",
    substring: "",
    possibleNames: [],
    players: [],
    heroes: {},
    timer: 0,
    toggle: "strict",
    selectedHero: null,
  };

  onSubmit = async (data) => {};

  handleSubmit = async (playerId) => {
    // event.preventDefault();
    // const player = await axios.get(`https://api.fault-ai.de/players/by-substring/${this.state.playerId}/strict`);
    this.setState({
      substring: "",
      possibleNames: [],
    });
    const { data: player } = await axios.get(
      `https://api.playfault.com/getEloData/${playerId}`
    );
    const heroesResponse = await axios.get(
      `https://api.playfault.com/getPlayerHeroStats/${playerId}`
    );
    const heroes = heroesResponse.data.heroes;
    this.setState({
      // players: [{ ...player, heroes:heroes.sort((a,b) => a.matches - b.matches) }, ...this.state.players],
      players: [{ ...player, heroes }, ...this.state.players],
    });
    // this.setState({ playerId: '' });
  };

  onChange = async (event) => {
    event.preventDefault();
    const substring = event.target.value;
    this.setState({ substring, timer: Date.now() });
    if (!!substring) {
      setTimeout(async () => {
        if (
          this.state.timer < Date.now() - 300 &&
          this.state.substring === substring
        ) {
          const response = await axios.get(
            `https://api.fault-ai.de/players/by-substring/${substring}/${this.state.toggle}`
          );
          const possibleNames = response.data || [];
          this.setState({ possibleNames });
        }
      }, 500);
    }
  };

  onToggle = () =>
    this.setState({
      toggle: this.state.toggle === "fuzzy" ? "strict" : "fuzzy",
    });

  createKDAString = (player) => {
    const relevantHeroes = Object.keys(player.heroes).filter(
      (hero) => !player.selectedHero || hero === player.selectedHero
    );
    const killSum = this.sum(relevantHeroes.map((e) => player.heroes[e].kills));
    const assistSum = this.sum(
      relevantHeroes.map((e) => player.heroes[e].assists)
    );
    const deathSum = this.sum(
      relevantHeroes.map((e) => player.heroes[e].deaths)
    );
    const response = `${killSum} / ${deathSum} / ${assistSum} (${(
      (killSum + assistSum) /
      deathSum
    ).toFixed(2)})`;
    return response;
  };

  createWinRatioString = (player) => {
    const relevantHeroes = Object.keys(player.heroes).filter(
      (hero) => !player.selectedHero || hero === player.selectedHero
    );
    const winSum = this.sum(relevantHeroes.map((e) => player.heroes[e].wins));
    const gameSum = this.sum(relevantHeroes.map((e) => player.heroes[e].games));
    const response = `${winSum} : ${gameSum - winSum} (${(
      (winSum / gameSum) *
      100
    ).toFixed(0)}%)`;
    return response;
  };

  average = (input) => input.reduce((a, v, i) => (a * i + v) / (i + 1));

  sum = (input) => input.reduce((a, b) => a + b);

  render() {
    return (
      <div class="App-team-container">
        {this.state.players[0] && (
          <div>
            Average Elo:{" "}
            {parseInt(
              this.average(
                this.state.players.map((d) => d.MMR),
                10
              )
            )}
          </div>
        )}
        <input
          type="text"
          class="App-input"
          value={this.state.substring}
          onChange={this.onChange}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !!this.state.possibleNames[0]) {
              this.setState({
                playerId: this.state.possibleNames[0].playerId,
              });
              this.handleSubmit(this.state.possibleNames[0].playerId);
            }
          }}
          // onSubmit={() => console.log('HE DID IT!')}
          // onChange={this.onChange}
          placeholder="Enter Player Name"
          required
        />
        <div class="App-button" onClick={this.onToggle}>
          {this.state.toggle === "strict" ? "caseSensitive" : "caseInsensitive"}
        </div>
        {this.state.possibleNames.map((d) => (
          <div
            className="playerNameSelectionBox"
            onClick={() => {
              this.setState({
                playerId: d.playerId,
              });
              this.handleSubmit(d.playerId);
            }}
          >
            {d.username}
          </div>
        ))}
        {this.state.players.map((player) => (
          <div>
            <div className="App-player-name">
              {player.username}
              <div
                className="App-player-delete App-crazy-glow"
                onClick={() => {
                  const newPlayers = this.state.players.filter(
                    (d) => d.username !== player.username
                  );
                  console.log(this.state.players, newPlayers, player.username);
                  this.setState({ players: newPlayers });
                }}
              >
                x
              </div>
            </div>
            <div className="playerBox">
              {/* <div className="App-player-heroes">{d.heroes && Object.keys(d.heroes)[0] && Object.keys(d.heroes).sort((a, b) => d.heroes[b].games - d.heroes[a].games).map((d) => (<img class="App-hero-icon" onClick={(event) => this.setState({ selectedHero: event.target.value })} src={`https://api.playfault.com/imagecdn/portraits/${d}.jpg`} />))}</div> */}
              {/* <div className="App-player-heroes">{d.heroes && Object.keys(d.heroes)[0] && Object.keys(d.heroes).sort((a, b) => d.heroes[b].games - d.heroes[a].games).map((d) => (<img class="App-hero-icon" src={`https://api.playfault.com/imagecdn/portraits/${d}.jpg`} />))}</div> */}
              <div className="App-player-heroes">
                {player.heroes &&
                  Object.keys(player.heroes)[0] &&
                  Object.keys(player.heroes)
                    .filter(
                      (hero) =>
                        !player.selectedHero || hero === player.selectedHero
                    )
                    .sort(
                      (a, b) => player.heroes[b].games - player.heroes[a].games
                    )
                    .map((e) => (
                      <div
                        className="App-hero-container App-crazy-glow"
                        onClick={() => {
                          const newPlayers = [...this.state.players];
                          const updatePlayer = newPlayers.find(
                            (i) => i.username === player.username
                          );
                          updatePlayer.selectedHero =
                            e === updatePlayer.selectedHero ? undefined : e;
                          this.setState({
                            players: newPlayers,
                          });
                        }}
                      >
                        <img
                          class="App-hero-icon"
                          src={`https://api.playfault.com/imagecdn/portraits/${e}.jpg`}
                        />
                      </div>
                    ))}
              </div>
              {/* <div className="App-player-heroes">{d.heroes && Object.keys(d.heroes)[0] && Object.keys(d.heroes).sort((a, b) => d.heroes[a].matches - d.heroes[b].matches).map((d) => (<img class="App-hero-icon" src={`https://api.playfault.com/imagecdn/portraits/${d}.jpg`} />))}</div> */}
              <div className="App-player-stats">{`${
                player.MMR ? `MMR: ${player.MMR} ` : ""
              }`}</div>
              <div className="App-player-stats">{`${
                (!player.heroes && "First Match") ||
                (!!Object.keys(player.heroes).length &&
                  `${this.createKDAString(player)}`)
              }`}</div>
              <div className="App-player-stats">{`${
                (!player.heroes && " ") ||
                (!!Object.keys(player.heroes).length &&
                  `${this.createWinRatioString(player)}`)
              }`}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="leftTeam App-container">{<PlayerForm />}</div>
        <div className="App-logo-container">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="rightTeam App-container">{<PlayerForm />}</div>
      </header>
    </div>
  );
};

export default App;
